<template>
    <ValidationProvider :name="name" :rules="rules" v-slot="{ errors, valid }" :vid="vid" :immediate="immediate">
        <v-text-field
            v-model="inputPassword"
            :label="label"
            :type="show1 ? 'text' : 'password'"
            :hint="hint" persistent-hint :name="name"
            :append-icon="show1 ? 'fas fa-eye' : 'fas fa-eye-slash'"
            :prepend-icon="icon"
            counter
            @click:append="show1 = !show1"
            required
            :error-messages="errors" :success="valid">
        </v-text-field>
    </ValidationProvider>
</template>

<script>
export default {
    name: 'Password',
    props: {
        password: { type: String },
        name: { type: String },
        label: { type: String },
        vid: { type: String, default: '' },
        hint: { type: String, default: '' },
        icon: { type: String, default: 'fas fa-lock' },
        required: { type: Boolean, default: false },
        immediate: { type: Boolean, default: true },
        rules: { type: String }
    },
    data() {
        return {
            show1: false,
            inputPassword: '',
        }
    },
    watch: {
        inputPassword: function () {
            this.$emit('update:password', this.inputPassword)
        }
    },

}
</script>