<template>
    <v-row>

        <v-col cols="12" sm="12" md="8" xs="12">
            <v-card class="elevation-2">
                <ValidationObserver ref="obs" v-slot="{ invalid, validated, handleSubmit }">
                    <v-form name="form" @submit.prevent="handleSubmit(updateProfile)">

                        <v-toolbar color="primary" dark text>
                            <v-toolbar-title>Editar perfil del usuario</v-toolbar-title>
                            <v-spacer></v-spacer>
                        </v-toolbar>

                        <v-card-text>
                            <v-container fluid>
                                <v-row>
                                    <v-col cols="12" md="6">
                                        <ValidationProvider rules="required" v-slot="{ errors, valid }" :immediate="true">
                                            <v-text-field label="Nombre de Usuario" v-model="usuario.username"
                                                prepend-icon="fas fa-user-secret" type="text" 
                                                :error-messages="errors" :success="valid" required>
                                            </v-text-field>        
                                        </ValidationProvider>
                                    </v-col>
                                    
                                    <v-col cols="12" md="6">
                                        <ValidationProvider rules="required|email" v-slot="{ errors, valid }" :immediate="true">
                                            <v-text-field label="Correo Electronico" v-model="usuario.email"
                                                prepend-icon="fas fa-envelope" type="email" 
                                                :error-messages="errors" :success="valid" required>
                                            </v-text-field>
                                        </ValidationProvider>
                                    </v-col>

                                    <v-col cols="12" md="6">
                                        <Password :password.sync="usuario.password" name="password"
                                        :label="hasPassword ? 'Contraseña*' : 'Contraseña'"
                                        hint="Modificar acceso al sistema opcional..." vid="password" rules="min:8|max:60"/>
                                    </v-col>

                                    <v-col cols="12" md="6">
                                        <Password :password.sync="usuario.passwordcp" name="passwordcp"
                                        :label="hasPassword ? 'Confirmar contraseña*' : 'Confirmar contraseña'" hint="Confirmar contraseña si quiere modificarla..."
                                        vid="passwordcp" :rules="hasPassword ? 'required|min:8|max:60|confirmed:password' : 'min:8|max:60'"/>
                                    </v-col>

                                    <v-col cols="12" class="text-right">
                                        <v-btn :loading="loading" color="primary" type="submit" :disabled="invalid || !validated">
                                            <v-icon left>fas fa-save</v-icon> Guardar cambios

                                            <template v-slot:handleSubmit(updateProfile)>
                                                <span>Guardando cambios..</span>
                                            </template>
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card-text>

                    </v-form>
                </ValidationObserver>
            </v-card>
        </v-col>

        <v-col cols="12" sm="12" md="4" xs= "12">
            <v-card class="elevation-2">
                <v-form name="form" @submit.prevent="uploadAvatar">

                    <v-card-text>
                        <v-container fluid>
                            <v-row>
                                <v-col cols="12">
                                    <v-img class="primary--text text--lighten-1 text-h2 align-end avatar" v-if="url" 
                                    :lazy-src="url" width="60%" rounded container aspect-ratio="1" style="margin: auto;">
                                        <v-card-title v-text="usuario.nombreCompleto"></v-card-title>
                                    </v-img>
        
                                    <div v-if="currentFile">
                                        <v-progress-linear :value="progress" color="light-blue" height="25" reactive>
                                            <strong>{{ progress }} %</strong>
                                        </v-progress-linear>
                                    </div>
                                </v-col>
        
                                <v-col cols="12">
                                    <v-file-input show-size label="Cambiar foto de perfil" @change="selectFile" 
                                    prepend-icon="fas fa-camera" :rules="rules" accept="image/png, image/jpeg, image/bmp">
                                    </v-file-input>
        
                                    <v-btn color="success" type="submit" dark small>
                                        Actualizar foto de perfil
                                        <v-icon right dark>fas fa-camera</v-icon>
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>
                </v-form>
            </v-card>
        </v-col>

    </v-row>
</template>
<script>
import UsuarioDataService from '@/service/usuario/usuarioDataService';
import UsuarioPerfilService from '@/service/usuario/UsuarioPerfilService';
import Camera from '@/assets/camera.png';
import Password from '@/components/shared/Password.vue';

export default {
    name: 'Profile',
    created() { this.obtenerUsuarioData(); },
    components: { Password },
    data() {
        return {
            idUsuario   : null,
            usuario     : [],
            loading     : false,
            rules: [
                value => !value || value.size < 2000000 || 'La foto debe ser menos de 2 MB!'
            ],
            url         : Camera,
            currentFile : undefined,
            progress    : 0,
            message     : "",
            fileInfos   : []
        }
    },
    methods: {
        async clear() {
            this.user.username = this.user.password = "";
            requestAnimationFrame(() => {
                this.$refs.obs.reset();
            });
        },
        obtenerUsuarioData(){
            const userId = UsuarioDataService.getCurrentUser().id;
            UsuarioDataService.getProfile(userId).then( response => {
                if (response.status == 200) {
                    this.usuario = response.data;
                    let { imagen } = response.data;
                    if ( imagen != "") {
                        this.url = `${this.$store.state.auth.rutaFichero}avatars/${imagen}`;
                    }
                }
            }).catch( err => {
                this.$swal.fire({ position : 'top-end', icon: 'error', text : err.response.data, title: 'Error al obtener los datos del usuario', showConfirmButton: false, timer: 1500 });
            });
        },
        updateProfile(){
            this.$swal.fire({
                title: 'Estas seguro?',
                text: "Si modificó su contraseña, le solicitara iniciar sesión nuevamente!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                cancelButtonText: 'No, Cancelar!',
                confirmButtonText: 'Si, guardar cambios!'
            }).then( result => {
                if (result.isConfirmed) {
                    UsuarioDataService.update(this.usuario).then( response => {
                        if( response.status == 200 ){
                            this.$swal.fire({ position : 'top-end', icon: 'success', text : 'Datos actualizados correctamente', title: 'Datos actualizados', showConfirmButton: false, timer: 1500 });

                            if( this.hasPassword ){
                                this.$swal.fire({ position : 'top-end', icon: 'success', text : 'Se ha actualizado el usuario y la contraseña', title: 'Favor de volver a iniciar sesión', showConfirmButton: false, timer: 1500 });
                                setTimeout(() => {
                                    this.$store.dispatch('auth/logout');
                                    this.$router.push('/');
                                }, 1500);
                            }
                        }
                    }).catch( err => {
                        this.$swal.fire({ position : 'top-end', icon: 'error', html : err, title: 'Error al actualizar los datos', showConfirmButton: false, timer: 1500 });
                    });
                }
            });

        },
        returnOptionLoad(){
            return {
                onUploadProgress: ( progressEvent ) => {
                    const { loaded, total } = progressEvent;
                    let percent = Math.floor( loaded * 100 / total );
                    this.progress = percent;
                }
            }
        },
        uploadAvatar(){
            if (!this.currentFile) {
                this.message = "Selecciona un avatar!";
                return;
            }

            this.message = "";
            UsuarioPerfilService.upload(this.usuario.id, this.currentFile, (event) => {
                this.progress = Math.round((100 * event.loaded) / event.total);
            }).then( response => {
                if (response.status == 200) {
                    this.message = response.data.message;
                    this.$swal.fire({ position : 'top-end', icon: 'success', text : response.data.message, title: 'Avatar actualizado exitosamente', showConfirmButton: false, timer: 2500 });
                } else if( rsp.status === 401 || rsp.status === 417 || rsp.status === 400 ){
                    this.$swal.fire({ position : 'top-end', icon: 'error', text : response.data.message, title: 'Foto de perfil no actualizada, validar fotografia', showConfirmButton: false, timer: 1500 });
                }

            }).catch((err) => {
                this.progress = 0;
                this.message = "No se pudo subir la foto!";
                this.currentFile = undefined;

                this.$swal.fire({ icon:'error', title: 'Error al actualizar la foto de perfil', text: err, showConfirmButton: false, timer: 1500 });
            });
        },
        selectFile(file) {
            this.progress = 0;
            this.currentFile = file;

            if (this.currentFile != null) {
                this.url = URL.createObjectURL(this.currentFile);
            }
        },
    },
    watch: {
        currentFile : function (val) {
            if ( val == null) {
                this.url = `${this.$store.state.auth.rutaFichero}avatars/${this.usuario.imagen}`;
            }
        }
    },
    computed: {
        hasPasswordMatch() {
            return this.usuario.password === this.usuario.passwordcp
        },
        hasPassword(){
            return this.usuario.password != undefined && this.usuario.password != ""
        },
        sameUser() {
            return this.usuario.id == UsuarioDataService.getCurrentUser().id
        }
    }
}
</script>
<style>
    .v-image__image--cover{
        background-size: cover;
        background-position: center;
        background-size: contain;
    }
    .v-image__image--preload {
        filter: blur(0.5px);
    }
</style>