import { http, authHeader } from '@/http-commons';

class UsuarioPerfilService {

    upload(id, file, onUploadProgress ){
        let formData = new FormData();
        formData.append("logotipo", file);
        
        return http.post(`api/v1/perfil/uploadProfile/${id}`, formData, 
        { headers: authHeader(), onUploadProgress });
    }
}
export default new UsuarioPerfilService();